import React, { createContext, useReducer, useState, useEffect } from "react";
import useWeb3 from "./hooks/useWeb3";
import reducers from "./reducers/Reducers";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const { getWeb3, getBalanceOf } = useWeb3()
  const initialState = {
    account: null,
    balance: 0,
    listMyNft: [],
    networkId: null,
    web3:null,
  }
  const [state, dispatch] = useReducer(reducers, initialState)
  const getInit = async () => {
    const web3 = await getWeb3()
    const networkId = await web3.eth.net.getId()
    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const balance = await getBalanceOf( account[0]);
    dispatch({
      type: "GET_NETWORK_ID",
      payload: {
        web3,
        networkId,
        account: account[0],
         balance
      }
    })
  }
  useEffect(() => {
    getInit()
  }, [])
  return (
    <AppContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
