import React, { useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { GradientText, Text } from '../../commonStyle';
import { CloseIcon, CopyIcon, OpenLinkIcon } from '../../Icons';
import { AppContext } from '../../../AppContext';
import useClickOutside from '../../../hooks/useClickOutside';

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in;
  ${({ isActive }) =>
    isActive
      ? css`
          opacity: 1;
          z-index: 10001;
          > div {
            opacity: 1;
          }
        `
      : css`
          opacity: 0;
          z-index: -1;
          > div {
            opacity: 0;
          }
        `}
`;

const ModalWrapper = styled.div`
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 0;
  border-radius: 8px;
  max-width: 468px;
  width: 95%;
  transition: 0.2s ease-in;
  box-shadow: 0px 4px 10px 0px #00000040;
  border:1px solid #00f1f1;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Line = styled.div`
  background: rgba(122, 110, 170, 0.1);
  height: 1px;
  width: 100%;
  margin: 1rem 0 1.5rem;
`;

const ModalInner = styled.div`
  padding: 0 1rem;
`;

const AccountBox = styled.div`
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  border-radius: 20px;
`;

const AccountAddress = styled(GradientText)`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 100;
  mask: linear-gradient(to right, #fff 80%, transparent);
`;

const StyledImg = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: 'auto';
`;

const CopiedWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  right: 0;
  top: -5px;
  opacity: ${({ isShow }) => (isShow ? '1' : '0')};
  transition: 0.2s ease-in-out;
`;

const StyledLink = styled.a`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    text-decoration-color: #000;
  }
`;

const ModalAccount = ({ isShowAcc, closeModalHandler }) => {
  const {state} = useContext(AppContext)
  const {account,balance} = state
  const [isCopied, setCopied] = useState(false);
  const modalRef = useRef(null);
  useClickOutside(modalRef, closeModalHandler);

  const onCopyAccount = () => {
    navigator.clipboard.writeText(account);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Overlay isActive={isShowAcc}>
      <ModalWrapper ref={modalRef}>
        <ModalHeader>
          <GradientText fontSize="22px" textTransform="capitalize" className="ms-0" style={{ paddingTop: 5 }}>
            Your Wallet
          </GradientText>
          <StyledCloseIcon width="24px" onClick={closeModalHandler} color="#777777" />
        </ModalHeader>
        <Line />
        <ModalInner>
          <div className="mb-2 position-relative">
            <Text fontSize="16px" className="ms-0 ">
              {' '}
              Your address:
            </Text>

            <CopiedWrapper isShow={isCopied}>
              <Text color="#00f1f1" fontSize="14px" className="ms-0">
                Copied!
              </Text>
            </CopiedWrapper>
          </div>

          <AccountBox className="mb-4" style={{ alignItems: 'center' }}>
            <AccountAddress fontSize="18px" className="ms-0" style={{ paddingTop: 5 }}>
              {account}
            </AccountAddress>
            <CopyIcon width="24px" style={{ cursor: 'pointer' }} onClick={onCopyAccount} fill="#00f1f1" />
          </AccountBox>

          <div className="mb-3">
            <div className="ms-0 mb-1 d-flex align-items-center justify-content-between pt-2">
              <GradientText fontSize="16px" fontWeight={400} className="ms-0" style={{ paddingTop: 5 }}>
                BUSD Balance
              </GradientText>
              <div className="d-flex align-items-center">
                <GradientText fontSize="16px" fontWeight={700} className="ms-0" style={{ paddingTop: 5 }}>
                  {(+balance).toFixed(4)}
                </GradientText>

                {/* <EtherIcon width="24px" className="ms-1" /> */}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <StyledLink
              className="d-flex align-items-center "
              href={`${process.env.REACT_APP_LINK_BSC}/address/${account}`}
              target="_blank"
            >
              <Text fontSize="16px" color="rgba(255,255,255,0.75)" className="ms-0">
                View on BscScan
              </Text>
              <OpenLinkIcon width="18px" fill="#00f1f1" className="ms-1" />
            </StyledLink>
          </div>
        </ModalInner>
      </ModalWrapper>
    </Overlay>
  );
};

export default React.memo(ModalAccount);
