import React, { useEffect, lazy, Suspense, useCallback, useState } from 'react';
import './App.css';
import GlobalStyled from './GlobalStyled';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import Navbar from './components/Navbar';
import AccountModal from './components/Modal/AccountModal';
import { ToastContainer } from 'react-toastify';

const Market = lazy(() => import('./components/NewMarket'));
const MyNFT = lazy(() => import('./components/MyNFT'));
const Category = lazy(() => import('./components/Category'));

const Layout = styled.div`
  background: #000;
  /* overflow: hidden; */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;

  @media (max-width: 768px) {
    background: #000;
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    height: auto;
  }
`;

const Wrapper = styled.div`
  padding-top: 100px;
  height: 100%;
`;

function App() {
  // const { account, networkId, myRefCode } = useContext(AppContext);
  const [isShowAcc, setShowAcc] = useState(false);

  const closeModalHandler = useCallback(() => setShowAcc(false), []);
  const openModalHandler = useCallback(() => setShowAcc(true), []);

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("chainChanged", (_chainId) =>
        window.location.reload()
      );
    }
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", (_accounts) =>
        window.location.reload()
      );
    }
  }, []);
  return (
    <Layout>
      <GlobalStyled />
      <Navbar 
        openModalHandler={openModalHandler}
      />

      <AccountModal isShowAcc={isShowAcc} closeModalHandler={closeModalHandler} />
      <Wrapper>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/" element={<Market />} />
              <Route path="/market" element={<Market />} />
              <Route path="/my-nft" element={<MyNFT />} />
              <Route path="/category" element={<Category />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </Wrapper>
      <ToastContainer className="toast-container" />
    </Layout>
  );
}

export default App;
