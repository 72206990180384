import React from "react";
import { ERC20Abi } from "../contracts";
import { BUSD_TOKEN_ADDRESS, LAUNCHPAD_ADDRESS } from "../config";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import busdabi from '../contracts/BUSD_abi.json'
export const getTokenContract = (web3, tokenAddress, Abi = ERC20Abi) =>
  new web3.eth.Contract(Abi, tokenAddress);

export const convertToWeii = (web3, amount) =>
  web3.utils.toWei(amount.toString(), "ether");
export const convertToTokens = (web3, amount) =>
  web3.utils.fromWei(amount.toString(), "ether");

const useWeb3 = (account) => {
  const onApproveHandler = async ( account) => {
    try {
      const tokenContract = await getContract(busdabi, BUSD_TOKEN_ADDRESS);
      await tokenContract.methods
        .approve(LAUNCHPAD_ADDRESS, Web3.utils.toWei("10000000000"))
        .send({ from: account });

      return true;
    } catch (e) {
      const error = e.message;
      console.log(e);
    }
  };

  const getBalanceOf = async (account) => {
    const tokenContract = await getContract(busdabi,BUSD_TOKEN_ADDRESS);

    const balance = await tokenContract.methods.balanceOf(account).call();
    return Web3.utils.fromWei(balance);
  };

  const getAllowanceOf = async (tokenDecimal,account) => {
try {
   const tokenContract = await getContract(busdabi, BUSD_TOKEN_ADDRESS);
   const balance = await tokenContract.methods
     .allowance(account, LAUNCHPAD_ADDRESS)
     .call();
   return Web3.utils.fromWei(balance);
} catch (error) {
  console.log(error);
}
   
  };

  const checkApprove = async (web3,account) => {
    const balance = await getAllowanceOf(web3,account);
    return +balance >= 10000000;
  };

  
 const getWeb3 = async (providerUrl) => {
  let web3;
  if (typeof window !== "undefined" && window.ethereum) {
    const provider = await detectEthereumProvider();
    web3 = new Web3(provider);
  } else {
    const provider = new Web3.providers.HttpProvider(providerUrl);
    web3 = new Web3(provider);
  }
  return web3;
};

 const getContract = async (abi, address) => {
  const web3 = await getWeb3(process.env.REACT_APP_BSC);
  return new web3.eth.Contract(abi, address);
};

  return {
    getBalanceOf,
    onApproveHandler,
    getAllowanceOf,
    checkApprove,
    getContract,
    getWeb3
  };
};

export default useWeb3;
