import React from 'react';

const BSC = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
        fill="#F3BA2F"
      />
      <path
        d="M6.43614 7.65213L8.49951 5.58875L10.5639 7.65319L11.7646 6.45256L8.49951 3.1875L5.23551 6.4515L6.43614 7.65213ZM3.18701 8.5L4.38764 7.29938L5.58826 8.5L4.38764 9.70063L3.18701 8.5ZM6.43614 9.34788L8.49951 11.4113L10.5639 9.34681L11.7646 10.5469L8.49951 13.8125L5.23551 10.5485L5.23392 10.5469L6.43614 9.34788ZM11.4108 8.5L12.6114 7.29938L13.812 8.5L12.6114 9.70063L11.4108 8.5ZM9.71713 8.49894H9.7182V8.5L8.49951 9.71869L7.28242 8.50213L7.28029 8.5L7.28242 8.49841L7.49545 8.28484L7.59904 8.18125L8.49951 7.28131L9.71767 8.49947L9.71713 8.49894Z"
        fill="white"
      />
    </svg>
  );
};

export default BSC;
