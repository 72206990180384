import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContext from './AppContext';
import 'antd/dist/antd.css';
import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <AppContext>
    <Router>
      <App />
    </Router>
  </AppContext>,
  document.getElementById('root'),
);

serviceWorker.unregister();
