const reducers = (state, action) => {
    switch (action.type) {
        case "GET_NETWORK_ID":
            return {
                ...state,
                networkId: action.payload.networkId,
                web3: action.payload.web3,
                account: action.payload.account,
                balance: action.payload.balance
            }
        case "ACCOUNT_INIT":
            return {
                ...state,
                account: action.payload
            }
        default:
            return state;
    }
}

export default reducers