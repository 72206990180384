import React, { useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { AppContext } from "../../AppContext";
import { Flex, Text } from "../SwapBoard";
import { isMobile } from "react-device-detect";
import { ShowMoreIcon } from "../Icons";
import useClickOutside from "../../hooks/useClickOutside";
import { OutlineButton, NFTImage } from "../commonStyle";
import { Button, Drawer, Space } from "antd";
import Web3 from "web3";

export const NAV_HEIGHT = "120px";

const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 1000;
  height: ${NAV_HEIGHT};
`;

const StyledLogoIcon = styled.img`
  width: ${({ width }) => width ?? "100%"};
  height: auto;
  margin-right: 8px;
  z-index: 100;
`;

const StyledLink = styled.div`
  transition: 0.2 ease-out;

  font-weight: 700;
  ${({ active }) => active && `cursor:default;`}
  text-decoration: unset;
  position: relative;
  text-align: center;
  p {
    transition: 0.2s ease-out;
    color: rgba(255, 255, 255, 0.55);
  }

  :hover {
    p {
      color: rgba(255, 255, 255, 1);
    }
  }
  ${({ active }) =>
    active &&
    `
    p {
      color: rgba(255, 255, 255, 1);
    };
  :after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    top:110%;
    background:rgba(255, 255, 255, 1);
    // transform: rotateZ(354deg);
  }
  `}
`;

const StyledMenuButton = styled(ShowMoreIcon)`
  cursor: pointer;
`;

const NavLinkWrapper = styled.div`
  top: -10%;
  opacity: 0;
  z-index: -1;
  ${({ isShow }) =>
    isShow && `top: calc(100% + 12px);opacity:1;z-index:1;padding:1rem;`}
  position: absolute;
  background: #fff;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  flex-direction: column;
  left: -78px;
  width: 200px;
  transform: translateX(-50%);
  & > * + * {
    margin-top: 1rem;
  }
  @media screen and (min-width: 768px) {
    opacity: 1;
    flex-direction: row;
    box-shadow: unset;
    background: transparent;
    border: unset;
    border-radius: 0;
    z-index: 1;
    position: unset;
    justify-content: center;
    width: auto;
    transform: unset;
    margin-left: 25rem;
    * {
      margin-top: 0;
    }
  }
`;

export const NewText = styled(Text)`
  font-family: Orbitron, sans-serif;
  font-size: 26px;
`;

const NewTextTwo = styled(Text)`
  background: -webkit-linear-gradient(273deg, #c04342 0%, #e40304 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-text-stroke: 1.5px white;
`;

const NewButton = styled.div`
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: 0.2s ease-in;
  }
`;

const RenderConnectButton = (account, handleConnect, openModalHandler) => {
  if (window.ethereum && !isMobile) {
    if (!account) {
      return (
        <div
          onClick={handleConnect}
          className="position-relative"
          style={{ cursor: "pointer" }}
        >
          <img src="/images/connect_metamask.png" alt="" />
          <div
            className="position-absolute top-50 start-50 translate-middle text-center"
            style={{
              color: "#FFFFFF",
              fontWeight: "500",
              fontSize: "22px",
              cursor: "pointer",
              width: "100%",
              fontFamily: "Orbitron",
            }}
          >
            Connect Metamask
          </div>
        </div>
      );
    }
  } else {
    if (isMobile)
      if (!account) {
        return (
          <a
            href={process.env.REACT_APP_METAMASK_DOMAIN}
            className="position-relative"
            style={{ cursor: "pointer" }}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src="/images/connect_metamask.png"
              alt=""
              className="w-100 h-100"
            />
            <div
              className="position-absolute top-50 start-50 translate-middle text-center"
              style={{
                color: "#FFFFFF",
                fontWeight: "500",
                fontSize: isMobile ? "16px" : "22px",
                cursor: "pointer",
                width: "100%",
                fontFamily: "Orbitron",
              }}
            >
              Connect Metamask
            </div>
          </a>
        );
      } else {
        return (
          <div>
            <NewButton onClick={openModalHandler} className="position-relative">
              <img
                style={{ height: 60 }}
                src="/images/metamaskBtn.png"
                alt=""
              />
              <div
                className="position-absolute top-50 start-50 translate-middle"
                style={{
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "20px",
                  cursor: "pointer",
                  fontFamily: "Orbitron",
                }}
              >
                {account.slice(0, 5)}...{account.slice(-5)}
              </div>
            </NewButton>
          </div>
        );
      }
    return (
      <OutlineButton
        as="a"
        target="_blank"
        href="https://metamask.io/download.html"
      >
        Install Metamask
      </OutlineButton>
    );
  }

  if (account)
    return (
      <div>
        <NewButton onClick={openModalHandler} className="position-relative">
          <img style={{ height: 60 }} src="/images/metamaskBtn.png" alt="" />
          <div
            className="position-absolute top-50 start-50 translate-middle"
            style={{
              color: "#FFFFFF",
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              fontFamily: "Orbitron",
            }}
          >
            {account.slice(0, 5)}...{account.slice(-5)}
          </div>
        </NewButton>
      </div>
    );
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Navbar = ({ openModalHandler }) => {
  const { state, dispatch } = useContext(AppContext);
  const { networkId, account, web3 } = state;
  const { pathname } = useLocation();
  const [isShowLink, setShowLink] = React.useState(false);
  const [open, setOpen] = useState(false);
  const navRef = useRef(null);
  useClickOutside(navRef, () => {
    if (isShowLink) setShowLink(false);
  });
  const handleConnect = async () => {
    await window.ethereum.enable();
    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    dispatch({
      type: "ACCOUNT_INIT",
      payload: account[0],
    });
  };
  const switchNetworkHandler = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (error) {
      if (error.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: process.env.REACT_APP_CHAIN_NAME,
              chainId: Web3.utils.toHex(chainId),
              nativeCurrency: { name: "BNB", decimals: 18, symbol: "BNB" },
              rpcUrls: [process.env.REACT_APP_BSC],
            },
          ],
        });
      }
    }
  };
  const query = useQuery();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <NavWrapper>
      <div style={{ maxWidth: 1680, width: "100%", padding: "0 15px" }}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="h-100 position-relative"
        >
          <Flex
            alignItems="center"
            as={Link}
            to="/"
            style={{ textDecoration: "unset" }}
          >
            <StyledLogoIcon src="/image.png" width="100px" />
          </Flex>

          <div
            className="position-relative mx-md-auto "
            style={{ marginRight: 5 }}
            ref={navRef}
          >
            <StyledMenuButton
              width="25px"
              onClick={() => setShowLink((prev) => !prev)}
              className="d-md-none"
            />

            <NavLinkWrapper
              className="d-flex align-items-center"
              isShow={isShowLink}
            >
              <StyledLink
                as={Link}
                to={
                  query.get("ref")
                    ? `/market?ref=${query.get("ref")}`
                    : "/market"
                }
                className="text-center"
                active={
                  pathname.includes("market") || !pathname.includes("my-nft")
                }
              >
                <NewText fontSize="32px" fontWeight={500} className="ms-0">
                  Market
                </NewText>
              </StyledLink>

              {account && (
                <StyledLink
                  className={`ms-1 ms-md-5 text-center`}
                  as={Link}
                  to={
                    query.get("ref")
                      ? `/my-nft?ref=${query.get("ref")}`
                      : "/my-nft"
                  }
                  active={pathname.includes("my-nft")}
                >
                  <NewText
                    fontSize="32px"
                    fontWeight={500}
                    className="ms-0"
                    textAlign="center"
                    style={{}}
                  >
                    My NFT
                  </NewText>
                </StyledLink>
              )}
            </NavLinkWrapper>
          </div>

          <Flex alignItems="center" style={isMobile ? { display: "none" } : {}}>
            {networkId + "" !== process.env.REACT_APP_CHAIN_ID && account ? (
              <div
                onClick={() =>
                  switchNetworkHandler(process.env.REACT_APP_CHAIN_ID)
                }
                className="position-relative"
                style={{ cursor: "pointer" }}
              >
                <img src="/images/connect_metamask.png" alt="" />
                <div
                  className="position-absolute top-50 start-50 translate-middle text-center"
                  style={{
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "22px",
                    cursor: "pointer",
                    width: "100%",
                    fontFamily: "Orbitron",
                  }}
                >
                  Switch Network
                </div>
              </div>
            ) : (
              RenderConnectButton(account, handleConnect, openModalHandler)
            )}
          </Flex>
          <Button
            onClick={showDrawer}
            className="d-flex align-items-center d-md-none mr-2 bg-transparent border-0"
          >
            <img src="https://creaturehunters.world/_next/static/media/Menu-symbol-mob.410a7ab1.png?imwidth=48" />
          </Button>
        </Flex>
      </div>

      <Drawer placement={"right"} width={300} onClose={onClose} open={open}>
        <div className="d-flex flex-column justify-content-center">
          <div className="text-white mb-3 text-center link_menu fs-3">
            <Link
              to={
                query.get("ref") ? `/market?ref=${query.get("ref")}` : "/market"
              }
              active={
                pathname.includes("market") || !pathname.includes("my-nft")
              }
            >
              Market
            </Link>
          </div>
          {account && (
            <div className="text-white mb-3 text-center link_menu fs-3">
              <Link
                to={
                  query.get("ref")
                    ? `/my-nft?ref=${query.get("ref")}`
                    : "/my-nft"
                }
                active={pathname.includes("my-nft")}
              >
                My NFT
              </Link>
            </div>
          )}
          {networkId + "" !== process.env.REACT_APP_CHAIN_ID && account ? (
            <div
              onClick={() =>
                switchNetworkHandler(process.env.REACT_APP_CHAIN_ID)
              }
              className="position-relative"
              style={{ cursor: "pointer" }}
            >
              <img src="/images/connect_metamask.png" alt="" />
              <div
                className="position-absolute top-50 start-50 translate-middle text-center"
                style={{
                  color: "#FFFFFF",
                  fontWeight: "500",
                  fontSize: "22px",
                  cursor: "pointer",
                  width: "100%",
                  fontFamily: "Orbitron",
                }}
              >
                Switch Network
              </div>
            </div>
          ) : (
            RenderConnectButton(account, handleConnect, openModalHandler)
          )}
        </div>
      </Drawer>
    </NavWrapper>
  );
};

export default Navbar;
