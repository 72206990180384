import styled from 'styled-components';

export const MainButton = styled.button`
  --main-color: linear-gradient(-90deg, #f79f28, #f87421);
  text-decoration: none;
  outline: none;
  border: 7px solid #ffb800;
  width: 222px;
  background: var(--main-color);
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1rem;
  transition: 0.1s ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  &.success {
    background: var(--main-color);
    border-radius: 10px;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    filter: brightness(1.1);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const OutlineButton = styled(MainButton)`
  border-radius: 30px;
  background: rgb(247, 248, 250);
  position: relative;
  color: #f79f28;
  :hover {
    color: #fff;
  }
  :before {
    border-radius: 30px;
    position: absolute;
    content: '';
    background: linear-gradient(-90deg, #f79f28, #f87421);
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    position: absolute;
    z-index: -1;
  }
`;

export const Text = styled.p`
  margin-left: 8px;
  color: ${({ color }) => color ?? 'rgba(255,255,255,1)'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? '34px'};
  ${({ textTransform }) => textTransform && `text-transform:${textTransform};`}
  ${({ fontFamily }) => fontFamily === 'Playfair' && 'font-family: Playfair Display SC;'} 
font-family:Orbitron, sans-serif;

  line-height: ${({ lineHeight }) => lineHeight ?? 1};
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ truncate }) =>
    truncate &&
    `
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width:150px;
    `}
    ${({ linear }) =>
    linear &&
    `
    background: linear-gradient(-90deg, #f79f28, #f87421);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`;

export const GradientText = styled(Text)`
  color: rgba(255,255,255,0.7);
`;

export const StyledNFTImg = styled.img`
  // width: ${({ width }) => width ?? '50%'};
  height: auto;
  min-height: 100px;
  border-radius: 5px 5px 0 0;
`;

export const NFTImage = styled(StyledNFTImg)`
  /* border-radius: 15px 15px 0 0; */
  max-height: 500px;
`;
